import { type SportItem } from '../services/sportService'
import { type Club } from './Clubs'

export interface FirebaseProviderData {
  uid: string
  displayName: string
  email: string
  photoURL: string
  providerId: string
}

interface SelectOption {
  label: string
  value: string
}

export interface AddressProps {
  address?: string
  address2?: string
  number?: string
  addressNumber?: string
  district?: string
  zipcode?: string
  currentCity: string
  currentState: string
  country?: string
}

export interface MetadaProps {
  lastSignInTime: string
  creationTime: string
}

export interface PaymentAccount {
  createdAt: string
  customerId: string
  id: string
  monthlyIncome: string
  professionalOccupation: string
  recipientId: string
  updatedAt: string
  userId: string
}

export interface UserProfile {
  id?: string
  displayName?: string
  firstName?: string
  lastName?: string
  email?: string
  photoURL?: string
  username?: string
  phoneNumber?: string
  birthDate?: string
  taxNumber?: string
  user?: any // User that comes from Firebase
  clubs?: Club[]
  sports?: SportItem[]
  providerData?: FirebaseProviderData[]
  isAdmin?: boolean
  address?: AddressProps
  metadata?: MetadaProps
  customClaims?: any
  stravaId?: string
  socialsNetwork?: socialMidia[]
  paymentAccount?: PaymentAccount
}

export interface socialMidia {
  title: string
  url: string
}

export interface UserProfileForm {
  displayName?: string
  email?: string
  photoURL?: string
  username?: string
  phoneNumber?: string
  birthDate?: string
  taxNumber?: string
  user?: any // User that comes from Firebase
  clubs?: Club[]
  sports?: SportItem[]
  providerData?: FirebaseProviderData[]
  uid?: string
  isAdmin?: boolean
  metadata?: MetadaProps
  address?: string
  address2?: string
  number?: string
  district?: string
  zipcode?: string
  currentCity?: string
  currentState?: string
  currentStateOption?: SelectOption
  currentCityOption?: SelectOption
  currentSportsOption?: SportItem[]
  currentClubsOption?: Club[]
  country?: string
  customClaims?: any
  socialsNetwork?: socialMidia[]
}

export enum Roles {
  superAdmin = 'SUPER_ADMIN',
}
