import React from 'react'

import './MyBalance.scss'

import MyBalanceEmpty from './MyBalanceEmpty'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'
import { MyBalanceDataBank } from './MyBalanceDataBank'

export const MyBalanceSummary: React.FC<any> = ({
  setCurrentStep,
  balance,
  bankAccount,
  hasSold
}) => {
  return (
    <div>
      {!hasSold && (
        <MyBalanceEmpty/>
      )}

      {hasSold &&
        (
          <div>
            <div className="balanceAvailableHeader">
              Saldo Disponível
            </div>
            <div className="balanceAvailable">
              <div className="balanceAvailableBalance">
                {(balance?.currentAmount).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              </div>
              <div className="balanceAvailableWithdraw">
                <Button
                  variant="primary"
                  disabled={balance?.currentAmount === 0}
                  type="button"
                  onClick={() => {
                    setCurrentStep(2)
                  }}>
                  Resgatar
                </Button>
              </div>
            </div>
            <div className="balanceFuture">
              <div className="balanceFutureHeader">Saldo em liberação</div>
              <div className="balanceFutureBalance">
                {(balance?.pendingAmount).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              </div>
              <p>O prazo de liberação é de 14 dias após a confirmação do recebimento do equipo</p>
            </div>
          </div>
        )
      }
      <MyBalanceDataBank bankAccount={bankAccount}/>
    </div>
  )
}
