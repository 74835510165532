import React, { type FormEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as Yup from 'yup'
import FormField from '../../components/FormComponents/FormField'
import TextField from '../../components/FormComponents/TextField'
import { type BankAccount, type BankAccountError } from '../../models/BankAccount'

import bankAccountService from '../../services/bankAccountService'
import availableBanksService from '../../services/availableBanksService'

import './MyBalanceBankAccountUpdate.scss'
import CleaveField from '../../components/FormComponents/CleaveField'
import SelectField from '../../components/FormComponents/SelectField'
import { ButtonV2 } from '../../components/Button/ButtonV2'
import { toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage/ToastMessage'
import _, { set } from 'lodash'
import ListingsProvider from '../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../components/Navbar/Navbar'
import ReturnHeader from '../../components/ReturnHeader/ReturnHeader'
import MyBalanceBankAccountUpdateLoading from './MyBalanceBankAccountUpdateLoading'
import checkoutService from '../../services/checkoutService'

interface SelectOptionProps {
  label: string
  value: string
}

interface AvailableBankOption {
  bankNumber: string
  id: string
  name: string
}

const bankAccountSchema = Yup.object().shape({
  bankNumber: Yup.string().required('Campo obrigatório'),
  branchNumber: Yup.string()
    .min(1, 'Agência deve ter no mínimo 1 número')
    .max(5, 'Agência deve ter no máximo 5 números')
    .required('Campo obrigatório'),
  branchCheckDigit: Yup.string(),
  accountNumber: Yup.string().required('Campo obrigatório'),
  accountCheckDigit: Yup.string().required('Campo obrigatório'),
  holder: Yup.object().shape(
    {
      fullname: Yup.string().required('Campo obrigatório'),
      taxDocument: Yup.string().required('Campo obrigatório')
    }),
  monthlyIncome: Yup.string().required('Campo obrigatório'),
  professionalOccupation: Yup.string().required('Campo obrigatório')
})

const occupationOptions = [
  { label: 'Estudante', value: 'student' },
  { label: 'Profissional Liberal', value: 'freelancer' },
  { label: 'Funcionário(a) de Empresa Privada', value: 'private_employee' },
  { label: 'Funcionário(a) Público(a)', value: 'public_employee' },
  { label: 'Empresário(a)', value: 'business_owner' },
  { label: 'Aposentado(a)', value: 'retired' },
  { label: 'Desempregado(a)', value: 'unemployed' },
  { label: 'Outro', value: 'other' }
]

const monthlyIncomeOptions = [
  { label: 'Até R$1.500', value: '1500' },
  { label: 'R$1.500 - R$2.500', value: '2500' },
  { label: 'R$2.501 - R$5.000', value: '5000' },
  { label: 'R$5.001 - R$10.000', value: '10000' },
  { label: 'R$10.001 - R$20.000', value: '20000' },
  { label: 'Acima de R$20.000', value: '20001' }
]

const MyBalanceBankAccountUpdate: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)
  const [bankAccount, setBankAccount] = useState<BankAccount | null>(null)
  const [errors, setErrors] = useState<BankAccountError | null>(null)
  const [availableBanks, setAvailableBanks] = useState<SelectOptionProps[]>([])
  const history = useHistory()

  useEffect(
    () => {
      const loadData = async () => {
        try {
          setIsLoading(true)
          const response = await availableBanksService.get()

          const allBanks = response.availableBanks
            .map((bank: AvailableBankOption) => ({
              value: bank.bankNumber,
              label: bank.name
            }))

          setAvailableBanks(allBanks)

          const bankAccount_ = await bankAccountService.get()
          const { paymentAccount } = await checkoutService.account.getPaymentAccount()

          if (bankAccount_) {
            setBankAccount({
              ...bankAccount_,
              bankName: allBanks.find((bank: any) => bank.value === bankAccount_.bank),
              holder: {
                fullname: bankAccount_.holderName,
                taxDocument: {
                  number: bankAccount_.holderDocument
                }
              },
              monthlyIncome: monthlyIncomeOptions.find(option => option.value === paymentAccount?.monthlyIncome) || null,
              professionalOccupation: occupationOptions.find(option => option.value === paymentAccount?.professionalOccupation) || null
            })
          }
        } catch (e: any) {
          const { isWarn, message, error } = e

          if (error.response.status === 404) {
            return
          }

          isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
        } finally {
          setIsLoading(false)
        }
      }

      void loadData()
    },
    []
  )

  const handleChangeHolderInfo = (name: string, value: string) => {
    if (name === 'fullname') {
      setBankAccount({
        ...bankAccount,
        holder: {
          ...bankAccount?.holder,
          fullname: value
        }
      })
    } else if (name === 'taxNumber') {
      setBankAccount({
        ...bankAccount,
        holder: {
          ...bankAccount?.holder,
          taxDocument: {
            ...bankAccount?.holder?.taxDocument,
            number: value
          }
        }
      })
    }
  }

  const handleChangeInput = (name: string, value: string) => {
    setBankAccount({
      ...bankAccount,
      [name]: value
    })
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setErrors(null)
    setIsButtonLoading(true)
    try {
      const payload = {
        bankNumber: bankAccount?.bankName?.value,
        branchNumber: bankAccount?.branchNumber,
        accountNumber: bankAccount?.accountNumber,
        accountCheckDigit: bankAccount?.accountCheckDigit,
        ...(!_.isEmpty(bankAccount?.branchCheckDigit) && { branchCheckDigit: bankAccount?.branchCheckDigit }),
        holder: {
          taxDocument: bankAccount?.holder?.taxDocument?.number?.replace(/\D/g, ''),
          fullname: bankAccount?.holder?.fullname
        },
        monthlyIncome: bankAccount?.monthlyIncome?.value,
        professionalOccupation: bankAccount?.professionalOccupation?.value
      }

      await bankAccountSchema.validate(payload, { abortEarly: false })

      await bankAccountService.create({ ...payload })

      history.goBack()
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessage = e.inner.reduce((obj: any, item: any) => {
          set(obj, item.path, item.message)
          return obj
        }, {})

        setErrors(errorMessage)
      } else {
        const { isWarn, message, error } = e as any

        if (error?.response?.status === 422) {
          toast.warn(<ToastMessage label={'Conta bancária já cadastrada'}/>)
          return
        }

        isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
      }
    } finally {
      setIsButtonLoading(false)
    }
  }

  if (isLoading) {
    return <MyBalanceBankAccountUpdateLoading />
  }

  return (
    <div className="bankAccount-edit">
      <ListingsProvider >
        <Navbar />
      </ListingsProvider>
      <ReturnHeader title="DADOS BANCÁRIOS" border={ true } />
    <form className="bankAccount-form-edit" noValidate>
      <div className="headerBankAccount">
        <span>Precisamos dos seus dados para você vender ou comprar um equipo</span>
      </div>
      <div>
        <FormField
          name="bankName"
          label="Banco"
          validation={errors?.bankName}
          required
        >
          <SelectField
            value={bankAccount?.bankName}
            onChange={(bankName) => {
              setBankAccount({ ...bankAccount, bankName })
            }}
            placeholder=""
            options={availableBanks}
          />
        </FormField>
        <div className="gridCol2">
          <FormField
            name="branchNumber"
            label="Agência"
            validation={errors?.branchNumber}
            required
          >
            <TextField
              name="branchNumber"
              type="text"
              value={bankAccount?.branchNumber}
              onChange={handleChangeInput}
            />
          </FormField>
          <FormField
            name="branchCheckDigit"
            label="Dígito da Agência"
            validation={errors?.branchCheckDigit}
            required
          >
            <TextField
              name="branchCheckDigit"
              type="text"
              value={bankAccount?.branchCheckDigit}
              onChange={handleChangeInput}
            />
          </FormField>
        </div>
        <div className="gridCol2">
          <FormField
            name="accountNumber"
            label="Conta corrente"
            validation={errors?.accountNumber}
            required
          >
            <TextField
              name="accountNumber"
              type="text"
              value={bankAccount?.accountNumber}
              onChange={handleChangeInput}
            />
          </FormField>
          <FormField
            name="accountCheckDigit"
            label="Dígito da Conta"
            validation={errors?.accountCheckDigit}
            required
          >
            <TextField
              name="accountCheckDigit"
              type="text"
              value={bankAccount?.accountCheckDigit}
              onChange={handleChangeInput}
            />
          </FormField>
        </div>
        <FormField
          name="fullname"
          label="Nome como está no seu banco"
          validation={errors?.holder?.fullname}
          required
        >
          <TextField
            name="fullname"
            type="text"
            value={bankAccount?.holder?.fullname}
            onChange={handleChangeHolderInfo}
          />
        </FormField>
        <FormField
          name="taxNumber"
          label="CPF"
          validation={errors?.holder?.taxDocument?.number}
          required
        >
          <CleaveField
            name="taxNumber"
            type="text"
            inputMode="numeric"
            maxLength={14}
            options={{
              delimiters: ['.', '.', '-'],
              blocks: [3, 3, 3, 2],
              uppercase: true
            }}
            value={bankAccount?.holder?.taxDocument?.number}
            onChange={handleChangeHolderInfo}
          />
        </FormField>

        <FormField
          name="monthlyIncome"
          label="Renda mensal"
          validation={errors?.monthlyIncome}
          required
        >
          <SelectField
            id="monthlyIncome"
            value={bankAccount?.monthlyIncome}
            onChange={(monthlyIncome) => {
              setBankAccount({ ...bankAccount, monthlyIncome })
            }}
            placeholder=""
            options={monthlyIncomeOptions}
          />
        </FormField>

        <FormField
          name="professionalOccupation"
          label="Ocupação profissional"
          validation={errors?.professionalOccupation}
          required
        >
          <SelectField
            id="monthlyIncome"
            value={bankAccount?.professionalOccupation}
            onChange={(professionalOccupation) => {
              setBankAccount({ ...bankAccount, professionalOccupation })
            }}
            placeholder=""
            options={occupationOptions}
          />
        </FormField>

        <ButtonV2
          variant="primary"
          margin="16px 0"
          isLoading={isButtonLoading}
          onClick={handleSubmit}
        >
          Cadastrar Conta
        </ButtonV2>
      </div>
    </form>
    </div>
  )
}

export default MyBalanceBankAccountUpdate
