import React, { useState } from 'react'

import './MyBalanceWithdraw.scss'

import { toast } from 'react-toastify'

import ToastMessage from '../../components/ToastMessage/ToastMessage'

import paymentService from '../../services/paymentsService'
import { ButtonV2, ButtonV2 as Button } from '../../components/Button/ButtonV2'
import { Link } from 'react-router-dom'
import { MyBalanceDataBank } from './MyBalanceDataBank'
import BigNumber from 'bignumber.js'

export const MyBalanceWithdraw: React.FC<any> = ({
  setCurrentStep,
  balance,
  setBalance,
  bankAccount
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleWithdraw = async () => {
    setIsLoading(true)
    if (!bankAccount) {
      toast.warn(<ToastMessage label="Informe uma conta bancária antes de solicitar a transferência."/>)
      return
    }

    if (balance?.currentAmount <= 0) {
      toast.warn(<ToastMessage label="Saldo insuficiente."/>)
      return
    }

    const payload = {
      amount: balance?.currentAmount,
      bankAccount: {
        id: bankAccount?.id || ''
      }
    }

    try {
      await paymentService.requestWithdraw(payload)
      setBalance({
        ...balance,
        currentAmount: 0
      })
      setCurrentStep(3)
      setIsLoading(false)
    } catch (err) {
      toast.error(
        <ToastMessage label="Ops! Aconteceu algo fora do esperado."/>
      )
      setIsLoading(false)
    }
  }

  const formatCurrency = (value: BigNumber) => {
    return value.toFormat(2, {
      prefix: 'R$ ',
      decimalSeparator: ',',
      groupSeparator: '.',
      groupSize: 3
    })
  }

  const currentAmount = new BigNumber(balance?.currentAmount || 0)
  const totalSold = currentAmount.dividedBy(0.85).decimalPlaces(2, BigNumber.ROUND_UP)
  const qipTax = totalSold.minus(currentAmount).decimalPlaces(2, BigNumber.ROUND_UP)
  const withdrawalFee = new BigNumber(3.67)
  const totalToReceive = currentAmount.minus(withdrawalFee)

  return (
    <>
      <div className="balanceAvailableDetail">
        <div className="raw">
          <div>
            Total em vendas
          </div>
          <div>
            {formatCurrency(totalSold)}
          </div>
        </div>
        <div className="raw light">
          <div>
            Comissão Qip
          </div>
          <div>
            - {formatCurrency(qipTax)}
          </div>
        </div>
        <div className="raw light">
          <div>
            Tarifa de saque
          </div>
          <div>
            - {formatCurrency(withdrawalFee)}
          </div>
        </div>
        <div className="raw total">
          <div>
            Total a receber
          </div>
          <div>
            {formatCurrency(totalToReceive)}
          </div>
        </div>
      </div>
      <div className="aboutFees">
        <Link target="_blank" to={{ pathname: 'https://qiper.zendesk.com/hc/pt-br/articles/25659772781979-Quais-s%C3%A3o-as-taxas-cobradas-pela-Qip' }}>Saiba mais sobre as taxas</Link>
      </div>
      <MyBalanceDataBank bankAccount={bankAccount} />
      <div className="withdrawInformation">
        <div className="confirmBalanceDescription">
          Ao confirmar o resgate o dinheiro será transferido para a conta
          indicada acima em até 24h.
          <br/>
          <b>Essa ação não pode ser cancelada.</b>
        </div>
        {/* <div className="detailBalanceDescription"> */}
        {/*  Você tem um resgate gratuito por mês. Caso já tenha feito um */}
        {/*  <br/> */}
        {/*  resgate, será cobrada uma taxa de R$ 3,50 */}
        {/* </div> */}
        <ButtonV2
          padding="0 10px"
          margin="25px 0 10px 0"
          type="button"
          variant="primary"
          disabled={!bankAccount}
          isLoading={isLoading}
          onClick={async () => {
            await handleWithdraw()
          }}
        >
          Confirmar resgate de{' '}
          {(balance?.currentAmount).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
        </ButtonV2>
        <Button
          type="button"
          variant="blueNoBorder"
          margin="0px"
          onClick={() => {
            setCurrentStep(1)
          }}
        >
          CANCELAR
        </Button>
      </div>
    </>
  )
}
