import { Container } from '../styles'
import EqiposList from '../../../components/EqiposList/EqiposList'
import React, { useContext } from 'react'
import { ListingsContext } from '../../../components/ListingsProvider/ListingsProvider'
import ListingHeader from './ListingHeader'
import ListingEmpty from './ListingEmpty'
import ListingAboutUs from './ListingAboutUs'

const Listings = () => {
  const { listings, filter, refreshFilter, isLoading } = useContext(ListingsContext)
  const isFilterApplied = Object.values(filter || {}).some(arr => Array.isArray(arr) && arr.length > 0)

  return listings?.length === 0 && !isLoading
    ? <ListingEmpty/>
    : <Container>
      <ListingHeader isFilterApplied={isFilterApplied}/>
      <EqiposList
        listings={listings}
        isLoading={isLoading}
        filter={filter}
        refreshFilter={refreshFilter}
        isFilterApplied={isFilterApplied}
        isHomePage
        disableFilterModal
      />
      {!isFilterApplied && <ListingAboutUs/>}
    </Container>
}

export default Listings
