import React from 'react'
import { CallLoginContainer, ChamadaAspas, Container, Header, Hero, WelcomeContainer } from './styles'
import { ButtonV2 as Button } from '../../components/Button/ButtonV2'
import EqiposList from '../../components/EqiposList/EqiposList'
import { useHistory } from 'react-router-dom'
import { useAsync } from 'react-async-hook'
import { type Listing } from '../../models/Listings'
import eqipoService from '../../services/eqipoService'

const Landing = () => {
  const history = useHistory()

  const { result: listings, loading } = useAsync<Listing[]>(
    async () => await eqipoService.getHighlights(), [])

  return <Container>
    <Hero src="/images/qip-hero.png"/>
    <WelcomeContainer>
      <h2>NA QIP VOCÊ COMPRA E VENDE <br/>
        PRODUTOS ESPORTIVOS DE ORIGEM, <br/>
        PRÉ-AMADOS E COM HISTÓRIA
      </h2>
      <ChamadaAspas
        src="/images/chamada-aspas.svg"
        alt="background chamada-aspas"
      />
      <Button
        variant="blue"
        margin="15px 0"
        onClick={() => {
          history.push('/entrar')
        }}
      >
        VAMOS COMEÇAR?
      </Button>
      <p>
        De atleta para atleta, transparente, direta, simples e rápida.
        Uma experiência sem intermediadores e com máxima segurança financeira.
      </p>
    </WelcomeContainer>
    <Header>EQUIPOS INCRÍVEIS EM DESTAQUE</Header>
    <EqiposList listings={listings} isLoading={loading} disableHeader hidePrices isHomePage/>
    <CallLoginContainer>
      <Button
        variant="blueSecondary"
        margin="0"
        onClick={() => {
          history.push('/entrar')
        }}
      >
        TRAGA SEU EQUIPO PARA QIP
      </Button>
    </CallLoginContainer>
    <Hero src="/images/qipers-stories.png"/>
    <h3>TODO EQUIPO TEM HISTÓRIAS PRA CONTAR</h3>
    <WelcomeContainer>
      <p>
        Seja uma <strong><em>bike</em></strong> que levou alguém para a viagem da  vida,
        ou um <strong><em>relógio gps</em></strong> que contém 2 maratonas e muita saúde
        mental, até uma <strong><em>bóia de natação</em></strong> no mar que  trouxe
        confiança para que alguém acreditasse em seu talento. Na Qip todo equipo é muito
        mais do que um produto pois carrega a trajetória de alguém no esporte.
      </p>
    </WelcomeContainer>
  </Container>
}

export default Landing
